
$palette-lumi-darkblue: #000E37;
$palette-lumi-mediumblue: #0076BE;
$palette-lumi-lightblue: #1192D4;
$palette-lumi-grey: #575556;
$palette-lumi-black: #000000;
$palette-lumi-white: #FFFFFF;
$palette-lumi-highlight: #46CAA8;


$font-size: 0.875rem;
$font-weight-normal: 300;

$body-bg: #F3F4F4;
$body-color: #333333;

$primary: $palette-lumi-lightblue;
$secondary: #C6C6C6;

$link-decoration: none;

$btn-focus-box-shadow: red;
$btn-active-box-shadow: green;

$font-weight-base: 200;

@import '~bootstrap/scss/bootstrap.scss';

// 4. Include any optional Bootstrap CSS as needed
@import "~bootstrap/scss/utilities";

$border-radius: 2px;

$placeholder-color: #999999;




html {
    @media (max-width:499px) {
        font-size:70%;
    }
    @media (min-width:500px) {
        font-size:75%;
    }
    @media (min-width:800px) {
        font-size:85%;
    }
    @media (min-width:1150px) {
        font-size:92.5%;
    }
    @media (min-width:1300px) {
        font-size:100%;
    }
    @media (min-width:1520px) {
        font-size:110%;
    }
    @media (min-width:1700px) {
        font-size:120%;
    }
}

body {
    font-size:$font-size;

    a,
    a:hover,
    a:focus,
    a:visited,
    a:active,
    button,
    button:hover,
    button:focus,
    button:visited,
    button:active,
    input,
    input:hover,
    input:focus,
    input:visited,
    input:active 
    textarea,
    textarea:hover,
    textarea:focus,
    textarea:visited,
    textarea:active { outline:none !important; text-decoration:none !important; box-shadow:none !important; }
    input { background-color:#FFFFFF !important; }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 { margin-bottom:0; }
    h4 { font-size:1.2rem; }
    h5 { font-size:1rem; }
    strong { font-weight:400; }
    small,
    .small { font-size:80%; }
    .btn:not(.btn-link) { color:#FFFFFF; border-radius:$border-radius; }

    .text-placeholder { color:$placeholder-color; }

    .container-videoplayer {
        position:relative;
        .videoplayer { max-width:100%; height:auto !important; display:flex; border-radius:$border-radius; overflow:hidden; }
        .highlighted-area { border:solid $palette-lumi-highlight 6px; border-radius:$border-radius; position:absolute; z-index:10; }
        .container-seek {
            padding:0px 0.75rem; display:flex; flex-direction:column;
            .seek {
                width:100%; -webkit-appearance:none; height:3rem; border-radius:$border-radius; background-repeat:no-repeat; background-size: 100% 100%; background:#FFFFFF; background-image:linear-gradient(to right, #ff4500 5%, blue 25%, green);
                &::-webkit-slider-thumb { -webkit-appearance:none; width:15px; height:3.2rem; margin-top:-0.1rem; border-radius:$border-radius; background:#FEFEFE; box-shadow:0 0 2px 0 #555; transition:background .3s ease-in-out; }
                &::-moz-range-thumb { -webkit-appearance:none; width:15px; height:3.2rem; margin-top:-0.1rem; border-radius:$border-radius; background:#FEFEFE; box-shadow:0 0 2px 0 #555; transition:background .3s ease-in-out; }
                &::-webkit-slider-runnable-track { -webkit-appearance:none; box-shadow:none; border:none; background:transparent; }
                &::-moz-range-track { -webkit-appearance:none; box-shadow:none; border:none; background:transparent; }
            }
            label { text-align:right; color:$palette-lumi-grey; margin-top:0.5rem; font-size:0.8rem; }
        }
    }

    .container-notes {
        max-height:30rem; margin-top:2rem; overflow:scroll;
        .container-note {
            display:flex; align-items:start; justify-content:start; margin-bottom:1rem;
            &:last-child { margin-bottom:0px; }
            .container-note-data {
                display:flex; flex-direction:column; margin-left:1.5rem;
            }
        }
    }

    .flex-column { flex-direction:column; }
    .color-displayer {
        border-radius:$border-radius; border-style:solid; border-color:#FFFFFF; box-shadow:0px 1px 5px rgba(122, 122, 122, 0.45);
        &.big { width:5rem; height:5rem; border-width:3px; }
        &.small { width:3rem; height:3rem; border-width:2px; }
    }

    #wrapper {
        width:100vw; min-height:100vh; position:relative;
        #topnavbar {
            width:100%; height:3.75rem; position:fixed; top:0px; left:0px; z-index:999; background-color:$palette-lumi-white; box-shadow:0px 0px 9px rgba(122, 122, 122, 0.2);
            .navbar {
                width:100%; height:100%; padding:0.5rem 2rem;
                .navbar-brand {
                    height:2rem; padding:0px;
                    img { max-height:2rem; }
                }
                #navbar-menu {
                    a {
                        color:$body-color;
                        &.active,
                        &:hover { color:$primary; }
                    }
                    #btn-login,
                    #btn-logout {
                        border-radius:$border-radius; font-weight:400; padding:0.425rem 0.8rem; font-size:$font-size;
                        &:focus { box-shadow:none !important; }
                    }
                    #btn-logout {
                        color:$palette-lumi-grey;
                        &:hover { color:$primary; }
                    }
                }
            }
        }

        main {
            min-height:100vh; padding-top:3.75rem; padding-bottom:59px;

            .widget-container {
                padding:2rem 1rem 0rem 1rem;
                .widget {
                    min-height:100%; background-color:#FFFFFF; border-radius:$border-radius; box-shadow:0px 1px 3px rgba(122, 122, 122, 0.07);
                    .widget-header { padding:1rem 2rem; }
                    .widget-body {
                        padding:1rem 2rem 3rem 2rem;
                        & > .container-fluid { padding:0px; }
                    }

                    &#widget-devices {
                        .widget-body {
                            display:flex; align-items:center; justify-content:space-between; flex-direction:column;
                            button {
                                width:100%; margin-bottom:1.5rem;
                                &:last-child { margin-bottom:0px; }
                            }


                        }
                    }

                    &#widget-experiment {
                        .widget-body {
                            .selected-frame-container {
                                margin-top:2rem; display:flex; align-items:start; flex-direction:column;
                                .selected-frame-data {
                                    width:80%; display:flex; align-items:start; justify-content:space-between; margin-top:1rem;
                                }
                            }
                        }
                    }
                }
            }

            #developer-feature {
                #profilephoto { max-width:10rem; border-radius:$border-radius; }
                #profiledescription { text-align:center; font-style:italic; }
                #contacts-container {
                    display:flex; align-items:start; flex-direction:column;
                }
            }
        }

        #footer {
            width:100%; height:59px; position:absolute; bottom:0px; padding:20px 2rem 0.5rem 2rem; display:flex; align-items:center; justify-content:space-between; font-size:70%;
            a {
                color:$palette-lumi-grey;
                &:hover { color:$primary; }
            }
        }
    }

    .modal-content {
        border-radius:$border-radius;
        .modal-header {
             display:flex; align-items:center; justify-content:center; box-shadow:0px 0px 9px rgba(122, 122, 122, 0.1); z-index:1;
            .modal-title { font-size:1rem; }
        }
        .modal-body { background-color:$body-bg; padding:2rem 4rem; }
    }

}

